<template>
  <h3 class="text-3xl font-semibold mb-10">Learn from multiple courses/books</h3>
  <div class="flex flex-col gap-y-4 text-lg">
    <ProOrCon :isPro=false>
      <p><strong>No clear learning path</strong>.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Different teaching styles and methodologies 😵‍💫.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Different levels of quality and depth 😕.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Jumping between courses leaves <strong>gaps in understanding</strong>.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Receives <strong>inconsistent and incompatible advice</strong>.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Synthesizing knowledge from various sources is more challenging than
        following a single, integrated course.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Duplicated material from different sources.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Many resources are outdated.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Hard to get support and help.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>You get frustrated 😠 and stuck learning by yourself.</p>
    </ProOrCon>
  </div>
</template>

<script>
import ProOrCon from './ProOrCon.vue'

export default {
  components: {
    ProOrCon
  }
}
</script>
