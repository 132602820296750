<template>
  <li>
    <div class="flex flex-col p-4 hover:shadow-lg hover:rounded-md hover:bg-slate-100">
      <div class="flex place-items-center">
        <div class="h-8 w-8 mr-2 text-blue-500">
          <slot name="icon"></slot>
        </div>
        <p class="text-xl font-bold">{{ feature }}</p>
      </div>
      <div class="mt-4">
        <slot name="description"></slot>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'CohortFeatureComponent',
  props: ['feature']
}
</script>
