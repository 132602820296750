<template>
  <div class="flex ml-4 mt-2">
    <div class="flex md:hidden">
      <a v-bind:key="index" v-for="(vip, index) in mobileVips" :href="vip.url" target="_blank" :class="tailwindClasses">
        <img loading="lazy" :src="`${baseUrl}/${vip.img}`" :alt="vip.name">
      </a>
    </div>
    <div class="hidden md:flex">
      <a v-bind:key="index" v-for="(vip, index) in vips" :href="vip.url" target="_blank" :class="tailwindClasses">
        <img loading="lazy" :src="`${baseUrl}/${vip.img}`" :alt="vip.name">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ['vips', 'isLarge'],
  data () {
    const pxSize = this.isLarge === true ? 96 : 64
    const baseUrl = `https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,h_${pxSize},w_${pxSize}/website`

    const tailwindSize = this.isLarge === true ? 16 : 10
    let tailwindClasses = 'overlap-image border-2 border-gray-600 hover:border-white rounded-full'
    tailwindClasses += ` h-10 sm:h-${tailwindSize} w-10 sm:w-${tailwindSize}`

    return {
      tailwindSize,
      tailwindClasses,
      baseUrl,
      mobileVips: this.vips.filter(x => !x.hideOnMobile)
    }
  }
}
</script>

<style scoped>
.overlap-image {
  @apply ml-[-12px];
}
</style>
