<template>
  <section>
    <div class="grid grid-cols-10 text-center mt-8">
      <div class="col-span-3 flex flex-col justify-center gap-y-20 pr-14">
        <div>
          <p class="font-bold text-2xl">💪 Boost job security</p>
          <p class="text-xl">One-stop shop to master serverless so you can become your
            company's resident serverless expert fast.</p>
        </div>

        <div>
          <p class="font-bold text-2xl">📚 Never feel lost</p>
          <p class="text-xl">Structured learning path so you can follow our step-by-step
            learning process and build your knowledge.</p>
        </div>
      </div>

      <div class="col-span-4 flex flex-col justify-center gap-y-10">
        <img loading="lazy"
          class="w-[512px] max-h-[562px] self-center"
          src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,w_512/website/prsls-zero_to_hero.png">

        <div>
          <p class="font-bold text-2xl">🔄 Cutting-edge and Current</p>
          <p class="text-xl">Continuous updated with latest industry trends and AWS updates
            so you can stay current with best practices and maintain a cutting-edge skill set.</p>
        </div>
      </div>

      <div class="col-span-3 flex flex-col justify-center gap-y-20 pl-14">
        <div>
          <p class="font-bold text-2xl">🥇 #1 serverless course</p>
          <p class="text-xl">Taught by a world-class instructor so you get the best serverless
            learning experience possible.</p>
        </div>

        <div>
          <p class="font-bold text-2xl">👥 Professional connections</p>
          <p class="text-xl">Become part of our serverless community so you can ask questions
            and get tailored advice.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>
