<template>
  <h3 class="text-3xl font-semibold mb-10">Other Workshops</h3>
  <div class="flex flex-col gap-y-4 text-lg">
    <ProOrCon :isPro=false>
      <p>Cost <strong>$2,000</strong> or more.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Taught by full-time trainers who <strong>lack real-world experience</strong> with the technologies they teach.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Limited access to workshop materials after the workshop.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p><strong>No access to the instructor</strong> after the workshop.</p>
    </ProOrCon>
    <ProOrCon :isPro=false>
      <p>Only teaches you how to write and deploy your application.
      <strong>Limited coverage</strong> of testing, security, architecture design,
      and troubleshooting in production.</p>
    </ProOrCon>
  </div>
</template>

<script>
import ProOrCon from './ProOrCon.vue'

export default {
  components: {
    ProOrCon
  }
}
</script>
