<template>
  <div class="pt-16 max-w-5xl mx-auto min-h-screen px-4 pb-10">
    <p class="text-3xl md:text-4xl mb-10">Self-paced workshops</p>
    <p class="mb-4">
      Self-paced workshops take place over 4 weeks, with an average time commitment
      of <strong>10 to 15 hours per week</strong>.
    </p>
    <p class="mb-4">
      Each week will follow this schedule:
    </p>
    <ol class="flex flex-col gap-y-4">
      <li>
        <strong>Mondays</strong>: Lectures and learning exercises are released and
        accessible through the Thinkific platform.
      </li>
      <li>
        <strong>All week</strong>: Group chat via Slack with me and your class cohort;
        Feel free to ask me anything. I'll do my best to help you.
      </li>
      <li>
        <strong>Mondays</strong>, 11am and 10pm (Central European Time): Live recap
        and Q&A sessions (~1 hour) with me to recap the week and answer all your
        burning questions.
      </li>
    </ol>

    <p class="mt-5">
      You will have <strong>LIFETIME ACCESS</strong> to the videos and hands-on
      exercises from the workshop.
    </p>

    <div class="my-10">
      <h2 class="text-2xl font-medium mb-4">Why take this workshop?</h2>

      <div class="flex flex-col gap-y-4">
        <p>
          There are a lot of tutorials out there to teach you the basics but they
          barely scratch the surface of what you will need to build and run a serverless
          application in production.
        </p>
        <p>
          It's difficult to learn everything you need to know on your own by just
          consuming online contents like blogs and videos. Which are disconnected
          and often contradict each other because content creators have differing
          philosophies and backgrounds. And they often don't explain WHY their
          approach works in their specific context, and if the approach would even
          work for you.
        </p>
        <p>
          That's why I created this workshop, so I can teach you everything you
          need to know in a structured format and take you from the basics all
          the way through to more advanced concepts and practices that seasoned
          serverless users would learn from.
        </p>
        <p>
          AND you can ask and get help from one of the most respected and experienced
          developers in the serverless space. If you're wondering how some of the
          concepts can be applied in your environment, I'm just a Slack message away.
        </p>
      </div>
    </div>

    <div class="my-10">
      <h2 class="text-2xl font-medium mb-4">Who is this workshop for?</h2>

      <div class="flex flex-col gap-y-4">
        <p>
          I have run this workshop and taught over 1000 students, and it has
          proven useful to students from a variety of backgrounds, such as:
        </p>

        <ol class="list-disc list-inside flex flex-col gap-y-2">
          <li>
            <strong>Backend engineers</strong> whose main focus is building business
            logic and managing APIs and data processing pipelines (which can include
            monitoring and being on-call).
          </li>
          <li>
            <strong>DevOps engineers</strong> whose main focus is in managing the
            company's AWS environment and shared infrastructures such as CI/CD,
            VPCs, IAM users/roles and sometimes general AWS security.
          </li>
          <li>
            <strong>Security engineers</strong> whose main focus is in the overall
            security of the company's application, which spans across application
            security, AWS security, CI/CD pipelines as well as incident management.
          </li>
          <li>
            <strong>Fullstack engineers</strong> who have to dabble with both
            frontend and backend technologies and whom increasingly need to be
            fluent with AWS and its serverless offerings.
          </li>
          <li>
            <strong>Tech Lead</strong> and <strong>Architects</strong> who want to
            get a deeper understanding of serverless technologies so they can better
            help their teams make the best architectural decisions and solve
            architectural challenges they face.
          </li>
        </ol>

        <p>
          In other words, there's something for everyone here. However, to make the
          most of the course, you should have basic familiarity with AWS - e.g. you
          should understand what is IAM and how to make API requests to AWS using
          the AWS SDK or AWS CLI.
        </p>
      </div>
    </div>

    <div class="my-10">
      <h2 class="text-2xl font-medium mb-4">Curriculum</h2>

      <div class="flex flex-col">
        <div>
          <h3 class="text-xl font-medium mt-6 mb-3">Week 1, building serverless APIs</h3>
          <ol class="pl-5">
            <li>Introductions</li>
            <li>AWS Lambda 101</li>
            <li>Serverless framework 101</li>
            <li>Building APIs with API Gateway and Lambda</li>
            <li>Securing APIs with IAM authorization</li>
            <li>Cognito 101</li>
            <li>Securing APIs with Cognito User Pools</li>
            <li>API Gateway best practices</li>
          </ol>
        </div>

        <div>
          <h3 class="text-xl font-medium mt-6 mb-3">Week 2, testing and CI/CD</h3>
          <ol class="pl-5">
            <li>Writing integration tests for Lambda</li>
            <li>Writing acceptance tests for Lambda</li>
            <li>Setting up CI/CD pipelines</li>
            <li>CI/CD security best practice</li>
            <li>Configuration management with SSM Parameter Store</li>
            <li>How best to secure credentials & API keys</li>
          </ol>
        </div>

        <div>
          <h3 class="text-xl font-medium mt-6 mb-3">Week 3, event-driven architectures</h3>
          <ol class="pl-5">
            <li>Project organization</li>
            <li>Processing events in realtime with Lambda</li>
            <li>How to choose the right application integration service</li>
            <li>SNS vs SQS vs Kinesis vs EventBridge</li>
            <li>Error handling for realtime event processing systems</li>
            <li>Per-function IAM roles</li>
          </ol>
        </div>

        <div>
          <h3 class="text-xl font-medium mt-6 mb-3">Week 4, observability</h3>
          <ol class="pl-5">
            <li>Setting up log aggregation for Lambda functions</li>
            <li>Structured logging</li>
            <li>Sample debug logs in production</li>
            <li>Distributed tracing with X-Ray</li>
            <li>Capture and forward correlation IDs</li>
            <li>Lumigo</li>
            <li>Alerts you should have</li>
            <li>Right-sizing function memories</li>
            <li>Costly mistakes to avoid</li>
          </ol>
        </div>
      </div>
    </div>

    <a class="font-medium underline" href="/#dates">Back to home</a>
  </div>
</template>
