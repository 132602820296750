<template>
  <div v-if="showModal" class="fixed z-10 bottom-0 left-0 w-full bg-white px-1 sm:px-4 py-1 sm:py-2 border-t">
    <div class="hidden sm:flex container mx-auto items-center justify-between">
      <div class="pr-2 md:pr-5 text-sm">
        <h2 class="text-base sm:text-lg font-medium">Can we store cookies?</h2>
        <p class="text-xs">
          We use cookies to enhance your browsing experience, server personalized ads or
          content, and analyze our traffic.
          By clicking "Accept All", you consent to our use of cookies.
        </p>
      </div>
      <div class="flex flex-col sm:flex-row gap-2 text-sm sm:text-base">
        <button @click="acceptAll"
          class="min-w-fit rounded px-4 py-1 bg-blue-700 text-white font-bold">
          Accept All
        </button>
        <button @click="rejectNonEssential"
          class="min-w-fit rounded px-4 py-1 text-blue-700 border-2 border-blue-700">
          Reject Non-Essential
        </button>
      </div>
    </div>
    <div class="flex flex-col sm:hidden container mx-auto items-center justify-between">
      <p class="text-xs">
        We use cookies to enhance your browsing experience, server personalized ads or
        content, and analyze our traffic.
      </p>
      <div class="flex gap-2 text-xs">
        <button @click="acceptAll"
          class="min-w-fit rounded px-2 py-1 bg-blue-700 text-white font-bold">
          Accept All
        </button>
        <button @click="rejectNonEssential"
          class="min-w-fit rounded px-2 py-1 text-blue-700 border-2 border-blue-700">
          Reject Non-Essential
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookie, setCookie } from '../libs/utils'
import { pageView } from '../libs/analytics'
import * as rudderanalytics from 'rudder-sdk-js'

const COOKIE_NAME = 'non-essential-cookies-opted-out'

export default {
  props: ['page', 'pageName'],
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    rejectNonEssential () {
      setCookie(COOKIE_NAME, 'true', 365)
      this.showModal = false
    },
    acceptAll () {
      setCookie(COOKIE_NAME, 'false', 365)
      this.showModal = false

      rudderanalytics.load(
        global.RS_WRITE_KEY,
        global.RS_DATA_PLANE_URL)

      global.rudderanalytics = rudderanalytics

      const affiliateId = getCookie('affiliateId')
      pageView(this.page, this.pageName, affiliateId)

      this.initFacebook(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
      window.fbq('init', '1053262429148154')
      window.fbq('track', 'PageView')
    },
    initFacebook (window, document, scriptTag, url, fbqFunction, scriptElement, firstScript) {
      // If the fbq function already exists, exit early.
      if (window.fbq) {
        return
      }

      fbqFunction = window.fbq = function () {
        // If callMethod exists on fbqFunction, use it.
        // Otherwise, push the arguments into the queue.
        if (fbqFunction.callMethod) {
          fbqFunction.callMethod.apply(fbqFunction, arguments)
        } else {
          fbqFunction.queue.push(arguments)
        }
      }

      // Check if _fbq exists on window. If not, assign fbqFunction to it.
      if (!window._fbq) {
        window._fbq = fbqFunction
      }

      // Set properties on the fbq function.
      fbqFunction.push = fbqFunction
      fbqFunction.loaded = true
      fbqFunction.version = '2.0'
      fbqFunction.queue = []

      scriptElement = document.createElement(scriptTag)
      scriptElement.async = true
      scriptElement.src = url

      // Get the first script tag in the document.
      firstScript = document.getElementsByTagName(scriptTag)[0]

      // Insert the Facebook script before the first script tag.
      firstScript.parentNode.insertBefore(scriptElement, firstScript)
    }
  },
  mounted () {
    this.acceptAll()
  }
}
</script>
