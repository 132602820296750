<template>
  <div class="mx-auto mt-10 max-w-7xl flex flex-col">
    <div class=" grid grid-cols-2 items-start gap-x-5">
      <div class="rounded-box p-10">
        <OtherWorkshops />
      </div>

      <!-- this workshop vs other workshops -->
      <div class="rounded-box bg-green-200 p-10">
        <ThisWorkshop :state="{ vsOtherWorkshops: true }" />
      </div>
    </div>

    <div class=" grid grid-cols-2 items-start">
      <div class="rounded-box bg-[#E0E4E8] p-10">
        <IsolatedResources />
      </div>

      <!-- this workshop vs other workshops -->
      <div class="rounded-box p-10">
        <ThisWorkshop :state="{ vsIsolatedResources: true }" />
      </div>
    </div>
  </div>
</template>

<script>
import OtherWorkshops from '@/components/comparison/OtherWorkshops.vue'
import IsolatedResources from '@/components/comparison/IsolatedResources.vue'
import ThisWorkshop from '@/components/comparison/ThisWorkshopNonReactive.vue'

export default {
  components: {
    OtherWorkshops,
    IsolatedResources,
    ThisWorkshop
  }
}
</script>
