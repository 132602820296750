<template>
  <section class="text-center">
    <p class="text-3xl md:text-4xl lg:text-5xl tracking-tight font-black">
      <span>Zero-to-Hero with Serverless </span>
      <span class="typed-text">{{ fourWeeks }}</span>
    </p>

    <div class="mx-auto flex flex-col place-items-center sm:text-lg md:text-xl">
      <p class="font-semibold mb-2">
        Join 20+ AWS Heroes & Community Builders and 1000+ happy
        students in levelling up your serverless game & becoming
        the serverless expert in your company.
      </p>

      <VipsList :vips="vips" :isLarge=true />

      <p class="mt-10 text-gray-400 text-sm sm:text-lg">Trusted by students from these awesome companies</p>

      <ul class="grid grid-cols-3 md:grid-cols-6 w-full">
        <Logo :isLarge=true company="AWS" logo="website/logo-bw-aws.png" />
        <Logo :isLarge=true company="LEGO" logo="website/logo-bw-lego.png" />
        <Logo :isLarge=true company="Booking.com" logo="website/logo-bw-booking.png" />
        <Logo :isLarge=true company="HBO" logo="website/logo-bw-hbo.png" />
        <Logo :isLarge=true company="CapitalOne" logo="website/logo-bw-capitalone.png" />
        <Logo :isLarge=true company="Siemens" logo="website/logo-bw-siemens.png" />
      </ul>
    </div>
  </section>
</template>

<script>
import VipsList from './VipsList.vue'
import Logo from './Logo.vue'

const FOUR_WEEKS = 'in 4 weeks'

export default {
  components: {
    VipsList,
    Logo
  },
  data () {
    return {
      fourWeeks: '',
      vips: [{
        name: 'Sam Williams',
        img: 'logo-sam-williams.png',
        url: 'https://www.linkedin.com/in/sam-complete-coding',
        hideOnMobile: false
      }, {
        name: 'Sheen Brisals',
        img: 'logo-sheen-brisals.png',
        url: 'https://aws.amazon.com/developer/community/heroes/sheen-brisals',
        hideOnMobile: false
      }, {
        name: 'Matt Lewis',
        img: 'logo-matt-lewis.png',
        url: 'https://aws.amazon.com/developer/community/heroes/matt-lewis',
        hideOnMobile: false
      }, {
        name: 'Pawel Zubkiewicz',
        img: 'logo-pawel-zubkiewicz.png',
        url: 'https://aws.amazon.com/developer/community/heroes/pawel-zubkiewicz',
        hideOnMobile: false
      }, {
        name: 'Jannik Wempe',
        img: 'logo-jannik-wempe.png',
        url: 'https://twitter.com/JannikWempe',
        hideOnMobile: false
      }, {
        name: 'Vlad Ionescu',
        img: 'logo-vlad-ionescu.png',
        url: 'https://aws.amazon.com/developer/community/heroes/vlad-ionescu',
        hideOnMobile: true
      }, {
        name: 'Manoj Fernando',
        img: 'logo-manoj-fernando.png',
        url: 'https://aws.amazon.com/developer/community/heroes/manoj-fernando',
        hideOnMobile: false
      }, {
        name: 'Ibrahim Cesar',
        img: 'logo-ibrahim-cesar.png',
        url: 'https://www.linkedin.com/in/ibrahimcesar/',
        hideOnMobile: false
      }, {
        name: 'Albert Suwandhi',
        img: 'logo-albert-suwandhi.png',
        url: 'https://aws.amazon.com/developer/community/heroes/albert-suwandhi',
        hideOnMobile: true
      }, {
        name: 'Farzana Rahman',
        img: 'logo-farzana-rahman.png',
        url: 'https://www.linkedin.com/in/farzana-rahman-juthi-85aa9a25/',
        hideOnMobile: true
      }, {
        name: 'Tomasz Lakomy',
        img: 'logo-tomasz-lakomy.png',
        url: 'https://aws.amazon.com/developer/community/heroes/tomasz-lakomy',
        hideOnMobile: false
      }]
    }
  },
  mounted () {
    setTimeout(() => this.animateFourWeeks(), 700)
  },
  methods: {
    animateFourWeeks () {
      const timer = setInterval(() => {
        if (this.fourWeeks !== FOUR_WEEKS) {
          this.fourWeeks = FOUR_WEEKS.substring(0, this.fourWeeks.length + 1)
        } else {
          clearInterval(timer)
        }
      }, 70)
    }
  }
}
</script>

<style scoped>
.typed-text {
  border-right: .15em solid white;
  animation: blink-caret .75s step-end infinite;
}
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white; }
}
</style>
