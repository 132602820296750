<template>
  <div class="relative isolate bg-white pb-32 pt-24 sm:pt-32">
    <div class="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl" aria-hidden="true">
      <div class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end" aria-hidden="true">
      <div class="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]" style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" />
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-xl text-center">
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">What students are saying</p>
      </div>
      <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
        <figure class="rounded-2xl bg-white shadow-lg ring-1 ring-gray-900/5 sm:col-span-2 xl:col-start-2 xl:row-end-1">
          <blockquote class="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 sm:p-12 sm:text-xl sm:leading-8">
            <p>{{ `“${featuredTestimonial.body}”` }}</p>
          </blockquote>
          <figcaption class="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 px-6 py-4 sm:flex-nowrap">
            <img class="h-14 w-14 flex-none rounded-full bg-gray-50" :src="featuredTestimonial.author.imageUrl" alt="" />
            <div class="flex-auto">
              <div class="font-semibold text-lg">{{ featuredTestimonial.author.name }}</div>
              <div class="text-gray-600 text-lg">{{ featuredTestimonial.author.handle }}</div>
            </div>
            <img class="h-14 w-14 flex-none" :src="featuredTestimonial.author.logoUrl" alt="" />
          </figcaption>
        </figure>
        <div v-for="(columnGroup, columnGroupIdx) in testimonials" :key="columnGroupIdx" class="space-y-8 xl:contents xl:space-y-0">
          <div v-for="(column, columnIdx) in columnGroup" :key="columnIdx" :class="[(columnGroupIdx === 0 && columnIdx === 0) || (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1) ? 'xl:row-span-2' : 'xl:row-start-1', 'space-y-8']">
            <figure v-for="testimonial in column" :key="testimonial.author.handle" class="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5">
              <blockquote class="text-gray-900">
                <p>{{ `“${testimonial.body}”` }}</p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img class="h-10 w-10 rounded-full bg-gray-50" :src="testimonial.author.imageUrl" alt="" />
                <div>
                  <div class="font-semibold">{{ testimonial.author.name }}</div>
                  <div class="text-gray-600">{{ testimonial.author.handle }}</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const imageUrl = (filename, size = 80) => {
  return `https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,h_${size},w_${size}/website/${filename}`
}

const featuredTestimonial = {
  body: "Production-Ready Serverless covers everything serious practitioners should know. You could spend tens of thousands on costly mistakes, and years of dev time to learn these lessons, or you could spend a few days downloading Yan's extensive experience. The choice is clear!",
  author: {
    name: 'Shawn Wang',
    handle: '@swyx, host of Latent.Space',
    imageUrl: imageUrl('shawn-wang.png', 100),
    logoUrl: imageUrl('logo-latent-space.png', 100)
  }
}
const testimonials = [
  [
    [
      {
        body: "This workshop really helped to solidify what I was trying to learn on my own, I'm excited about applying the learnings in future projects!",
        author: {
          name: 'Vanessa Ho',
          handle: 'Software Developer',
          imageUrl: imageUrl('vanessa-ho.jpg')
        }
      },
      {
        body: 'The Production-Ready Serverless workshop with Yan was truly a game-changer for my understanding of serverless technology.',
        author: {
          name: 'Muhammad Siddiqui',
          handle: 'Backend Developer',
          imageUrl: imageUrl('muhammad-mughees.jpg')
        }
      },
      {
        body: "This workshop is a learning journey to discover serverless, guided by Yan's solid experience and best practices. In a short amount of time, you will become ready to create and configure a Production-Ready Serverless application.",
        author: {
          name: 'Luis Miguel Serrano',
          handle: 'Software Engineer',
          imageUrl: imageUrl('luis-miguel-serrano.jpg')
        }
      },
      {
        body: 'Yan is great at breaking down the complexity of serverless for someone who is less experienced with AWS, while at the same time providing advanced insights.',
        author: {
          name: 'Patricia Boh',
          handle: 'Full Stack Engineer',
          imageUrl: imageUrl('patricia-boh.jpg')
        }
      }
    ],
    [
      {
        body: 'By far the most comprehensive, concise, and up to date course for building real-world serverless applications.',
        author: {
          name: 'Nader Dabit',
          handle: 'Author of "Full Stack Serverless"',
          imageUrl: imageUrl('nader-dabit.jpg')
        }
      },
      {
        body: 'I highly recommend this workshop. Anyone who is serious about learning how to deliver serverless systems should attend.',
        author: {
          name: 'Iain MacLeod',
          handle: 'Tech Lead @ Skyscanner',
          imageUrl: imageUrl('iain-macleod.jpg')
        }
      },
      {
        body: "This workshop was so packed with practical goodness and insights. I feel like we got a direct brain download of all Yan's Serverless experience and wisdom!",
        author: {
          name: 'Tim Hacker',
          handle: 'Lead Software Engineer',
          imageUrl: imageUrl('tim-hacker.jpg')
        }
      }
    ]
  ],
  [
    [
      {
        body: 'If you want to learn Serverless and AWS, you can not find a better person than Yan. This is one of the best investments you can make.',
        author: {
          name: 'Bala Chalasani',
          handle: 'Founder @ Cloud Architectures',
          imageUrl: imageUrl('bala-chalasani.jpg')
        }
      },
      {
        body: 'Yan has created THE course about serverless. From zero to hero, he teaches you concepts followed by hands-on exercises. I especially valued his fast thinking during tough and very specific questions about architecture.',
        author: {
          name: 'Eduard Bargués',
          handle: 'DevOps Architect',
          imageUrl: imageUrl('eduard-bargues.jpg')
        }
      },
      {
        body: "This course lived up to the hype. You build real apps using a range of Serverless services in real AWS accounts while absorbing Yan's deep expertise. You are then very well prepared to start building complex business apps in production.",
        author: {
          name: 'Nick Tune',
          handle: 'Author of "Architecture Modernization"',
          imageUrl: imageUrl('nick-tune.jpg')
        }
      }
    ],
    [
      {
        body: "Yan's workshop allowed our team to get to grips with everything serverless. The exercises around testing were invaluable. This was an excellent investment for our team.",
        author: {
          name: 'Jordan Crocker',
          handle: 'Principal Engineer @ Crowdcube',
          imageUrl: imageUrl('jordan-crocker.jpg')
        }
      },
      {
        body: 'This course is a fantastic introduction to AWS and Serverless regardless of skill level. It is rich in useful information and well paced for you to understand what is being taught. Thanks so much Yan!',
        author: {
          name: 'Laura Jones',
          handle: 'Full Stack Developer',
          imageUrl: imageUrl('laura-jones.jpg')
        }
      },
      {
        body: "Yan's a real powerhouse when it comes to answering questions & live-debugging. Besides, he's a super cool person who is definitely fun to work with.",
        author: {
          name: 'Michael Fecher',
          handle: 'Freelancer',
          imageUrl: imageUrl('michael-fecher.jpg')
        }
      },
      {
        body: 'This workshop has accelerated my understanding of serverless, best practices and pitfalls. I am confident that I can use this learning to build great things!',
        author: {
          name: 'Simon Mellor',
          handle: 'Lead Rails Developer',
          imageUrl: imageUrl('simon-mellor.jpg')
        }
      }
    ]
  ]
]
</script>
