const { BE_URL } = require('./constants')

async function getNotificaitons () {
  const resp = await fetch(BE_URL + '/notifications', {
    cache: 'default'
  })

  const body = await resp.json()
  return body.notifications
}

module.exports = {
  getNotificaitons
}
