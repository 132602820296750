<template>
  <div class="pt-16 max-w-5xl mx-auto min-h-screen px-4 pb-10">
    <p class="text-3xl md:text-4xl mb-10">Instructor-led workshops</p>
    <p class="mb-4">
      Instructor-led workshops last over 2 days,
      running from 9am to 5pm <strong>Central European Time</strong>.
    </p>
    <p class="mb-4">
      The workshop sessions are conducted over <strong>Zoom</strong>.
      They are recorded and you will have <strong>LIFETIME ACCESS</strong>
      to the videos and hands-on exercises from the workshop. These will
      be shared with you after the workshop.
    </p>

    <div class="my-10">
      <h2 class="text-2xl font-medium mb-4">Why take this workshop?</h2>

      <div class="flex flex-col gap-y-4">
        <p>
          There are a lot of tutorials out there to teach you the basics but they
          barely scratch the surface of what you will need to build and run a serverless
          application in production.
        </p>
        <p>
          It's difficult to learn everything you need to know on your own by just
          consuming online contents like blogs and videos. Which are disconnected
          and often contradict each other because content creators have differing
          philosophies and backgrounds. And they often don't explain WHY their
          approach works in their specific context, and if the approach would even
          work for you.
        </p>
        <p>
          That's why I created this workshop, so I can teach you everything you
          need to know in a structured format and take you from the basics all
          the way through to more advanced concepts and practices that seasoned
          serverless users would learn from.
        </p>
        <p>
          AND you can ask and get help from one of the most respected and experienced
          developers in the serverless space. If you're wondering how some of the
          concepts can be applied in your environment, I'm just a Slack message away.
        </p>
      </div>
    </div>

    <div class="my-10">
      <h2 class="text-2xl font-medium mb-4">Who is this workshop for?</h2>

      <div class="flex flex-col gap-y-4">
        <p>
          I have run this workshop and taught over 1000 students, and it has
          proven useful to students from a variety of backgrounds, such as:
        </p>

        <ol class="list-disc list-inside flex flex-col gap-y-2">
          <li>
            <strong>Backend engineers</strong> whose main focus is building business
            logic and managing APIs and data processing pipelines (which can include
            monitoring and being on-call).
          </li>
          <li>
            <strong>DevOps engineers</strong> whose main focus is in managing the
            company's AWS environment and shared infrastructures such as CI/CD,
            VPCs, IAM users/roles and sometimes general AWS security.
          </li>
          <li>
            <strong>Security engineers</strong> whose main focus is in the overall
            security of the company's application, which spans across application
            security, AWS security, CI/CD pipelines as well as incident management.
          </li>
          <li>
            <strong>Fullstack engineers</strong> who have to dabble with both
            frontend and backend technologies and whom increasingly need to be
            fluent with AWS and its serverless offerings.
          </li>
          <li>
            <strong>Tech Lead</strong> and <strong>Architects</strong> who want to
            get a deeper understanding of serverless technologies so they can better
            help their teams make the best architectural decisions and solve
            architectural challenges they face.
          </li>
        </ol>

        <p>
          In other words, there's something for everyone here. However, to make the
          most of the course, you should have basic familiarity with AWS - e.g. you
          should understand what is IAM and how to make API requests to AWS using
          the AWS SDK or AWS CLI.
        </p>
      </div>
    </div>

    <div class="my-10">
      <h2 class="text-2xl font-medium mb-4">Curriculum</h2>
      <p>
        This is a rough schedule of what to expect on each day.
        However, depending on how everyone is doing, we will adapt the schedule
        as we go. My goal is to give everyone the time they need to do the exercises
        and ask questions, so you can get the most from these two days.
      </p>

      <div class="flex flex-col lg:flex-row justify-between">
        <div>
          <h3 class="text-xl font-medium mt-6 mb-3">Day 1</h3>
          <ol class="pl-5">
            <li>09:00 - Introductions</li>
            <li>09:05 - AWS Lambda 101</li>
            <li>09:45 - Serverless framework 101</li>
            <li>10:15 - Break</li>
            <li>10:30 - Building APIs with API Gateway and Lambda</li>
            <li>11:00 - Securing APIs with IAM authorization</li>
            <li>11:20 - Cognito 101</li>
            <li>11:30 - Securing APIs with Cognito User Pools</li>
            <li>12:00 - Lunch</li>
            <li>13:00 - API Gateway best practices</li>
            <li>13:20 - Writing integration tests for Lambda</li>
            <li>14:10 - Writing acceptance tests for Lambda</li>
            <li>14:30 - Break</li>
            <li>14:45 - Setting up CI/CD pipelines</li>
            <li>15:00 - CI/CD security best practice</li>
            <li>15:20 - Break</li>
            <li>15:30 - Configuration management with SSM Parameter Store</li>
            <li>16:00 - How best to secure credentials & API keys</li>
            <li>16:30 - Day 1 wrap up + open questions</li>
          </ol>
        </div>

        <div>
          <h3 class="text-xl font-medium mt-6 mb-3">Day 2</h3>
          <ol class="pl-5">
            <li>09:00 - Project organization</li>
            <li>09:20 - Processing events in realtime with Lambda</li>
            <li>10:15 - How to choose the right application integration service</li>
            <li>10:30 - Break</li>
            <li>10:45 - SNS vs SQS vs Kinesis vs EventBridge</li>
            <li>11:00 - Error handling for realtime event processing systems</li>
            <li>11:15 - Per-function IAM roles</li>
            <li>11:40 - Setting up log aggregation for Lambda functions</li>
            <li>12:00 - Lunch</li>
            <li>13:00 - Structured logging</li>
            <li>13:30 - Sample debug logs in production</li>
            <li>14:00 - Break</li>
            <li>14:10 - Distributed tracing with X-Ray</li>
            <li>15:10 - Break</li>
            <li>15:20 - Capture and forward correlation IDs</li>
            <li>16:00 - Alerts you should have</li>
            <li>16:15 - Right-sizing function memories</li>
            <li>16:30 - Day 2 wrap up + open questions</li>
          </ol>
        </div>
      </div>
    </div>

    <a class="font-medium underline" href="/#dates">Back to home</a>
  </div>
</template>
