<template>
  <div class="flex flex-col">
    <div class="bg-yellow-200 w-full p-2 sm:p-5 pb-5 sm:pb-10 hover:shadow-md">
      <WeeklyProgram :week="week1" />
    </div>
    <div class="bg-blue-200 w-full p-2 sm:p-5 pb-5 sm:pb-10 hover:shadow-md">
      <WeeklyProgram :week="week2" />
    </div>
    <div class="bg-green-200 w-full p-2 sm:p-5 pb-5 sm:pb-10 hover:shadow-md">
      <WeeklyProgram :week="week3" />
    </div>
    <div class="bg-orange-200 w-full p-2 sm:p-5 pb-5 sm:pb-10 hover:shadow-md">
      <WeeklyProgram :week="week4" />
    </div>
  </div>
</template>

<script>
import WeeklyProgram from './WeeklyProgram.vue'

export default {
  components: {
    WeeklyProgram
  },
  data () {
    return {
      week1: {
        name: 'Week 1: Introductions & REST APIs',
        color: 'yellow',
        themes: [
          {
            id: 'intro-to-aws-and-serverless',
            name: 'Intro to AWS & serverless',
            img: 'logo-week1-aws.png',
            lessons: [
              { name: 'Understand what we mean by "serverless"; and when it\'s the right choice for your workload.' },
              { name: 'Introduction to popular serverless services on AWS, including Lambda, DynamoDB, Cognito, API Gateway and Step Functions.' }
            ]
          },
          {
            id: 'lambda-deep-dive',
            name: 'Deep Dive into Lambda',
            img: 'logo-week1-lambda.png',
            lessons: [
              { name: 'Understand how AWS Lambda works under the hood - how it handles synchronous vs. asynchronous invocations, and how Lambda scales.' },
              { name: 'Understand when to use advanced features such as EFS integrations, provisioned concurrency, and Lambda extensions.' }
            ]
          },
          {
            id: 'intro-to-iac',
            name: 'Infrastructure-as-Code (IaC)',
            img: 'logo-week1-iac-v2.png',
            lessons: [
              { name: 'Introduction to infrastructure-as-code (IaC) and how CloudFormation & Terraform works.' },
              { name: 'All hands-on exercises are available in CDK, Terraform and Serverless Framework.' }
            ]
          },
          {
            id: 'apigw-lambda',
            name: 'REST API with API Gateway & Lambda',
            img: 'logo-week1-apigw-lambda.png',
            lessons: [
              { name: 'Understand how API Gateway works, and how to build REST APIs with API Gateway and Lambda.' },
              { name: 'Learn API Gateway best practices, how to secure your API and when to use API Gateway vs. ALB vs. Lambda Function URLs.' }
            ]
          }
        ]
      },
      week2: {
        name: 'Week 2: Testing & CI/CD',
        color: 'blue',
        themes: [
          {
            id: 'testing-sls',
            name: 'Testing Serverless Architectures',
            img: 'logo-week2-test-honeycomb.png',
            lessons: [
              { name: 'Understand the challenges with testing serverless architectures and how to tackle them.' },
              { name: 'Learn my strategy for testing serverless, which combines fast feedback loop of local tests with the accuracy of testing in the cloud.' },
              { name: 'Understand when to use unit vs. integration vs. e2e tests.' }
            ]
          },
          {
            id: 'gh-actions',
            name: 'CI/CD pipeline with GitHub Actions',
            img: 'logo-week2-gh-actions.png',
            lessons: [
              { name: 'Learn how to set up CI/CD pipelines with GitHub Actions, using AWS IAM federation.' }
            ]
          },
          {
            id: 'ephemeral-envs',
            name: 'Ephemeral Environments',
            img: 'logo-week2-temp-envs.png',
            lessons: [
              { name: 'Use ephemeral environments to supercharge your development workflow and allow many developers to work on the same codebase simultaneously.' },
              { name: 'Learn how to deal with serverful resources (i.e. such as RDS and OpenSearch) and how to share SSM parameters between ephemeral environments.' }
            ]
          },
          {
            id: 'config-management',
            name: 'Config & Secret Management',
            img: 'logo-week2-config.png',
            lessons: [
              { name: 'Introduction to SSM Parameter Store and Secrets Manager, and when to use which.' },
              { name: 'Learn best practices for storing and loading secrets.' }
            ]
          }
        ]
      },
      week3: {
        name: 'Week 3: Event-Driven Architectures & DDD',
        color: 'green',
        themes: [
          {
            id: 'evb-lambda',
            name: 'Intro to Event-Driven Architectures',
            img: 'logo-week3-evb-lambda.png',
            lessons: [
              { name: 'Introduction to EventBridge, and how to process events in real-time using Lambda and EventBridge.' },
              { name: 'Learn how to organize your system into services and repos.' },
              { name: 'Understand the different event bus topologies and how to choose the best one for your use case.' },
              { name: 'Learn best practices for building event-driven architectures - how to structure your events, version them, and how to archive and replay them.' }
            ]
          },
          {
            id: 'testing-eda',
            name: 'Testing Event-Driven Architectures',
            img: 'logo-week3-test-eda.png',
            lessons: [
              { name: 'Learn the smart way to test event-driven architectures.' },
              { name: 'Learn how to capture events in SNS and EventBridge and use them in your integration and e2e tests.' }
            ]
          },
          {
            id: 'domain-driven-design',
            name: 'Domain Driven Design (DDD)',
            img: 'logo-week3-ddd.png',
            lessons: [
              { name: 'Understand the basic concepts and tools from Domain Driven Design (DDD), such as bounded context, context mapping and Anti-Corruption Layers (ACL).' },
              { name: 'Learn how to apply DDD ideas to building Event-Driven Architectures.' },
              { name: 'Understand when to use commands vs. events and how much information you should include in events.' },
              { name: 'Understand what is Event Sourcing and CQRS, when they might be useful, and how you can implement them using serverless technologies.' }
            ]
          },
          {
            id: 'error-handling',
            name: 'Error Handling & Idempotency',
            img: 'logo-week3-error-handling.png',
            lessons: [
              { name: 'Learn best practices for error handling when processing asynchronous events.' },
              { name: 'Learn how to implement idempotency to ensure the same event is not processed more than once.' },
              { name: 'Learn how to deal with partial failures from batch event sources (e.g. SQS and Kinesis).' }
            ]
          }
        ]
      },
      week4: {
        name: 'Week 4: Observability, Cost & Performance',
        color: 'orange',
        themes: [
          {
            id: 'lambda-logging',
            name: 'Lambda Logging Best Practices',
            img: 'logo-week4-logging.png',
            lessons: [
              { name: 'Understand why structured logging is important, and why you should sample debug logs in production to minimize the cost of CloudWatch Logs.' },
              { name: 'We will discuss Lambda\'s advanced logging controls and how they work.' },
              { name: 'And I will show you some tips for keeping CloudWatch Logs cost under control, and how to bypass CloudWatch Logs altogether if you\'re using a 3rd-party log aggregation service already.' }
            ]
          },
          {
            id: 'xray-tracing',
            name: 'Intro to Distributed Tracing & X-Ray',
            img: 'logo-week4-tracing-v2.png',
            lessons: [
              { name: 'I will show you how to integrate with X-Ray and how to instrument your application to get the most out of X-Ray.' },
              { name: 'But at the end of the day, X-Ray is still a rather limited service... I will show you its limitations and why I prefer to use Lumigo in my projects instead.' },
              { name: 'Of course, I will give you a demo of Lumigo and how to use its no-code instrumentation to make debugging easier.' }
            ]
          },
          {
            id: 'lambda-alerting',
            name: 'Monitoring & Alerting Best Practices',
            img: 'logo-week4-alarm.png',
            lessons: [
              { name: 'Understand why, and how to record custom application metrics using the Embedded Metric Format.' },
              { name: 'Understand why you shouldn\'t use averages, and should prefer tail latencies instead.' },
              { name: 'And I will show you what alerts you should have in your serverless application.' }
            ]
          },
          {
            id: 'cost-optimizations',
            name: 'Performance and Cost Optimizations',
            img: 'logo-week4-cost.png',
            lessons: [
              { name: 'I will show you how to analyze and improve Lambda cold starts, and take a look at Lambda\'s new LLRT runtime.' },
              { name: 'I will explain how to right-size the memory setting for your Lambda functions, so you get the right balance between power and cost.' },
              { name: 'And I will show you how to model and project the cost of your application and some costly mistakes you need to watch out for!' }
            ]
          }
        ]
      }
    }
  }
}
</script>
