<template>
  <div class="bg-white py-14 sm:py-20">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
        <div class="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
          <div v-for="testimonial in testimonials" :key="testimonial.author.handle" class="pt-8 sm:inline-block sm:w-full sm:px-4">
            <figure class="rounded-2xl bg-gray-50 p-8 text-sm leading-6">
              <blockquote class="text-gray-900">
                <p>{{ `“${testimonial.body}”` }}</p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <img class="h-10 w-10 rounded-full bg-gray-50" :src="testimonial.author.imageUrl" alt="" />
                <div>
                  <div class="font-semibold text-gray-900">{{ testimonial.author.name }}</div>
                  <div class="text-gray-600">{{ testimonial.author.handle }}</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const imageUrl = (filename, size = 80) => {
  return `https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,h_${size},w_${size}/website/${filename}`
}

const testimonials = [
  {
    body: 'Yan delivers high-value training that reflects his deep practitioner experience.',
    author: {
      name: 'Craig Balding',
      handle: 'Cyber Security Consultant',
      imageUrl: imageUrl('craig-balding.jpg')
    }
  },
  {
    body: "Yan's content always delivers, being complete not only from a development perspective, but also complete in testing and CI.",
    author: {
      name: 'Murat Ozcan',
      handle: 'Staff Engineer @ Extend',
      imageUrl: imageUrl('murat-ozcan.jpg')
    }
  },
  {
    body: 'Yan really opened my eyes to new ways of developing with AWS serverless technology and how I can apply them in my day job.',
    author: {
      name: 'Andrew Maughan',
      handle: 'Senior Backend Developer @ CEF',
      imageUrl: imageUrl('andrew-maughan.jpg')
    }
  },
  {
    body: 'We learnt so much and went from zero to having a good understanding of the Serverless landscape.',
    author: {
      name: 'Quynh Nguyen',
      handle: 'Engineering Manager @ SimplyBusiness',
      imageUrl: imageUrl('quynh-nguyen.jpg')
    }
  },
  {
    body: 'This course will definitely increase your AWS skill level. This is a hands-on course on Serverless computing, which delivers a deep dive into AWS services. Complex concepts are explained in a clear and concise manner.',
    author: {
      name: 'Ben Quarless',
      handle: 'Senior Software Engineer',
      imageUrl: imageUrl('ben-quarless.jpg')
    }
  },
  {
    body: 'The workshop was quite informative and hands-on. In fact, the personal experience that Yan had shared during the workshop was extremely valuable to the professionals who run serverless applications in production.',
    author: {
      name: 'Manoj Fernando',
      handle: 'AWS Community Hero',
      imageUrl: imageUrl('manoj-fernando.jpg')
    }
  },
  {
    body: 'Learned so much! The way the course and exercises were structured was really helpful, allowing everyone to learn at their own pace with lot of opportunity for hands-on learning. Yan is a great teacher.',
    author: {
      name: 'Becky Pauley',
      handle: 'Platform Engineer @ Wealth Wizards',
      imageUrl: imageUrl('becky-pauley.jpg')
    }
  },
  // {
  //   body: "The workshop was very informative, both in terms of validating some approaches I've taken, and also showing how to improve in other areas. Yan shared his personal experiences with serverless and offered advice on specific challenges.",
  //   author: {
  //     name: 'Kaarel Sööt',
  //     handle: 'CTO @ Project Nebula',
  //     imageUrl: imageUrl('logo-kaarel-soot.jpg')
  //   }
  // },
  {
    body: 'Yan is incredibly knowledgeable when it comes to AWS and Serverless. His Production-Ready Serverless workshop was of huge benefit to our teams, even those who have already been working with AWS for the past 12 months.',
    author: {
      name: 'Billy Gibson',
      handle: 'Cloud Software Engineer @ CEF',
      imageUrl: imageUrl('billy-gibson.jpg')
    }
  },
  {
    body: 'I found the workshop very informative and practical. In other AWS courses, you only learn about the products. But in this workshop, you learn how to use them in the real-world. Yan gave me the building blocks that I can use in my own projects!',
    author: {
      name: 'Martijn van der Kamp',
      handle: 'DevOps Engineer',
      imageUrl: imageUrl('martin-van-der-kamp.jpg')
    }
  },
  {
    body: 'Best part of this course is you can discuss with Yan about your own production related problems. Thank you so much Yan for your effort.',
    author: {
      name: 'Farzana Rahman',
      handle: 'AWS Community Builder',
      imageUrl: imageUrl('logo-farzana-rahman.jpg')
    }
  },
  {
    body: 'It has been a truly invaluable learning experience for me. it really is an amazing course and covered very important parts. I would recommend this course to anyone who wants to better understand serverless and AWS.',
    author: {
      name: 'Mosleh Alnakib',
      handle: 'Software Engineer @ New10',
      imageUrl: imageUrl('mosleh-alnakib.jpg')
    }
  },
  {
    body: 'This is a unique experience packed with lots of advanced Serverless topics. The exercises covers lots of good information on managing AWS costs, improving performance and many more. I recommend this course to anyone who is looking to take their serverless skills to the next level.',
    author: {
      name: 'Subhamay Bhattacharyya',
      handle: 'AWS Data Engineer',
      imageUrl: imageUrl('subhamay-bhattacharyya.jpg')
    }
  },
  {
    body: "As someone who has learned serverless on his own, going through Yan's workshop gave me a lot of practical lessons and best practices that I can't wait to implement on my own projects. Yan has also been super helpful, and he answers all questions thrown at him.",
    author: {
      name: 'Jasper Gabriel',
      handle: 'Lead Software Engineer @ P33R Finance',
      imageUrl: imageUrl('jasper-gabriel.jpg')
    }
  },
  {
    body: 'This learning experience has been a game-changer in my development journey, I feel more empowered than ever to design resilient serverless architectures that can handle production-level demands.',
    author: {
      name: 'Sri Baabu',
      handle: 'Software Engineer',
      imageUrl: imageUrl('sri-baabu.jpg')
    }
  },
  {
    body: "I've been able to take what Yan has taught me and immediately incorporate them into my project, where before I was struggling! I am extremely happy I took this course!",
    author: {
      name: 'Scott Burgholzer',
      handle: 'AWS Community Builder',
      imageUrl: imageUrl('scott-burgholzer.jpg')
    }
  },
  {
    body: 'This is the right course if you want to quickly ramp up your AWS skills.',
    author: {
      name: 'Geyson Ferreira',
      handle: 'Freelancer',
      imageUrl: imageUrl('geyson-ferreira.jpg')
    }
  },
  {
    body: 'This course has not only expanded my skill set but also my perspective on building scalable cloud solutions.',
    author: {
      name: 'Demis Borgeaud',
      handle: 'DevOps Engineer',
      imageUrl: imageUrl('demis-borgeaud.jpg')
    }
  }
]
</script>
