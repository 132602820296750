const { BE_URL } = require('./constants')

const COUNTRY_CODE_URL = 'https://paddle-check-price.appsyncmasterclass.workers.dev'

async function getDiscount () {
  const countryCode = await fetch(COUNTRY_CODE_URL, {
    cache: 'default'
  }).then(x => x.text())

  const discountResp = await fetch(BE_URL + `/ppp-price/${countryCode}`, {
    cache: 'default'
  }).then(x => x.json())

  return discountResp?.discount || 0
}

module.exports = {
  getDiscount
}
