<template>
  <h1 class="text-xl sm:text-2xl font-bold">{{ week.name }}</h1>

  <div class="grid grid-cols-2 sm:grid-cols-4 gap-x-2 gap-y-2 sm:gap-x-4 lg:gap-x-10 mt-5 mx-4 lg:mx-10 justify-evenly text-center">
    <LessonGroup v-for="theme in week.themes"
      :key="theme.id"
      :name="theme.name"
      :img="theme.img"
      @clicked="toggleDetails(theme.id)" />
  </div>
  <div v-if="themeId !== null" class="mt-4 py-2 sm:py-4 lg:py-6 bg-white bg-opacity-80">
    <ul class="p-3 sm:p-4 flex flex-col gap-y-2 list-disc list-inside">
      <Lesson v-for="lesson in shownLessons" :key="lesson.name" :lesson="lesson" />
    </ul>
  </div>
</template>

<script>
import LessonGroup from './LessonGroup.vue'
import Lesson from './Lesson.vue'

export default {
  props: ['week'],
  components: {
    LessonGroup,
    Lesson
  },
  data () {
    return {
      themeId: null,
      shownLessons: []
    }
  },
  methods: {
    toggleDetails (id) {
      if (this.themeId === id) {
        this.themeId = null
      } else {
        this.themeId = id
      }

      const theme = this.week.themes.find(x => x.id === id)
      this.shownLessons = theme.lessons
    }
  }
}
</script>
