<template>
  <li class="sm:pl-4 flex gap-x-2">
    <p class="sm:block text-lg">{{ lesson.name }}</p>
  </li>
</template>

<script>
export default {
  name: 'LessonsComponent',
  props: ['lesson']
}
</script>
