<template>
  <section>
    <div class="text-base sm:text-xl font-thin">
      <p class="font-semibold">Schedule based on the following assumptions:</p>
      <ul class="list-inside list-disc">
        <li>10 - 15 hours per week</li>
        <li>either software development or operations background</li>
        <li>follow the lectures and do the exercises</li>
      </ul>

      <p class="mt-4">
        However, the workshop is <span class="font-semibold">self-paced</span>
        and you can <span class="font-semibold">take as much time as you need</span> 👍</p>
    </div>

    <div>
      <div class="grid grid-cols-3 gap-x-4 text-lg font-extralight">
        <!-- sad man + down arrow -->
        <div class="flex flex-col col-span-1 self-end">
          <img class="w-48 self-center"
            loading="lazy"
            src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,w_192/website/prsls-sad_man.png">

          <img class="w-16 self-center"
            loading="lazy"
            src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,w_64/website/down-arrow.png">

        </div>

        <!-- 3 confusions -->
        <div class="col-span-2 flex flex-col gap-y-4 text-sm sm:text-lg">
          <div class="flex flex-row">
            <img class="w-10 sm:w-16 h-10 sm:h-16 mr-1 sm:mr-5"
              loading="lazy"
              src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,w_64,h_64/website/prsls-sad_face.png">
            <p class="place-self-center">
              read many articles about serverless but
              <span class="font-semibold">
                don't know if you're doing it right 😵‍💫
              </span>
              and following best practices.
            </p>
          </div>

          <div class="flex flex-row">
            <img class="w-10 sm:w-16 h-10 sm:h-16 mr-1 sm:mr-5"
              loading="lazy"
              src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,w_64,h_64/website/prsls-sad_face.png">
            <p class="place-self-center">
              don't know <span class="font-semibold">how to choose between similar AWS services</span>
              and what trade-offs to consider
            </p>
          </div>

          <div class="hidden sm:flex flex-row">
            <img class="w-10 sm:w-16 h-10 sm:h-16 mr-1 sm:mr-5"
              loading="lazy"
              src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,w_64,h_64/website/prsls-sad_face.png">
            <p class="place-self-center">
              don't know how to test your application or how to debug it properly, so
              <span class="font-semibold">don't feel confident 😰</span>
              to run serverless workloads in production
            </p>
          </div>
        </div>
      </div>

      <!-- 4 week schedule -->
      <div class="my-1">
        <FourWeekProgram />
      </div>

      <div class="grid grid-cols-3 text-base sm:text-xl font-thin">
        <span class="col-span-2"></span>
        <img class="w-16 justify-self-center"
            loading="lazy"
            src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,w_64/website/down-arrow.png">

        <p class="col-span-2 text-right text-sm sm:text-xl w-48 sm:w-96 justify-self-end place-self-center">
          feel <span class="font-semibold">confident in what you're doing</span> and are able to
          <span class="font-semibold">make the right architecture decisions</span> for your serverless applications
        </p>
        <img class="w-48 justify-self-center"
          loading="lazy"
          src="https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,w_192/website/prsls-hero.png">
      </div>
    </div>
  </section>
</template>

<script>
import FourWeekProgram from '@/components/schedule-section/FourWeekProgram.vue'

export default {
  components: {
    FourWeekProgram
  }
}
</script>
