<template>
  <TopBannerV2 @ppp-available="onPppAvailable" @bf-available="onBfAvailable" />

  <div class="w-full flex flex-col bg-white">
    <!-- only here for the anchor -->
    <div id="home"></div>

    <div class="bg-black pt-40 px-2 pb-10 text-white">
      <RestrictedWidth>
        <HeroSection />
      </RestrictedWidth>
    </div>

    <div class="py-10 flex flex-col gap-y-20">
      <div class="hidden lg:block mx-5 md:mx-20">
        <ZeroToHeroSection />
      </div>

      <RestrictedWidth>
        <HighLevelScheduleSection />
      </RestrictedWidth>
    </div>

    <!-- only here for the anchor -->
    <div id="testimonials"></div>

    <TrustedBySectionV2 />
    <TestimonialsCloud />

    <!-- only here for the anchor -->
    <div id="pricing" class="mt-20 mb-10">
      <PricingSection ref="pricingSection"/>
    </div>

    <div class="mb-32 mx-auto md:max-w-6xl">
      <CurriculumSection />
    </div>

    <div id="comparison" class="mb-32">
      <div class="hidden lg:block">
        <ComparedToOthersSectionV3 />
      </div>

      <div class="lg:hidden">
        <ComparedToOthersSectionV1 />
      </div>
    </div>

    <RestrictedWidth>
      <div class="flex flex-col gap-y-20">
        <HappinessGuarantee/>

        <!-- only here for the anchor -->
        <div id="about-me"></div>

        <AboutSection />

        <!-- only here for the anchor -->
        <div id="faq"></div>

        <FaqSection />

        <ConvertKitForm />
      </div>
    </RestrictedWidth>

    <TestimonialsGrid />

    <FooterSection />

    <CookieOptOutBanner v-bind:page="`Home`" v-bind:pageName="`HomeView`" />

    <div class="alert-widget-container">
      <AlertWidget
        v-for="(notification, index) of notifications"
        :key="notification.timestamp"
        v-bind:notification="notification"
        class="alert-widget"
        :style="`animation-delay: ${index * 10 + 5}s;`"
        v-on:close-widget="clearNotifications()"
      />
    </div>
  </div>
</template>

<script>
import TopBannerV2 from '@/components/TopBannerV2.vue'
import RestrictedWidth from '@/components/home/RestrictedWidth.vue'
import CurriculumSection from '@/components/CurriculumSection.vue'
import HeroSection from '@/components/HeroSection.vue'
import HighLevelScheduleSection from '@/components/HighLevelScheduleSection.vue'
import ZeroToHeroSection from '@/components/ZeroToHeroSection.vue'
import ComparedToOthersSectionV1 from '@/components/ComparedToOthersSection.vue'
import ComparedToOthersSectionV3 from '@/components/ComparedToOthersSectionV3.vue'
import PricingSection from '@/components/PricingSection.vue'
import HappinessGuarantee from '@/components/HappinessGuarantee.vue'
import AboutSection from '@/components/AboutSection.vue'
import FaqSection from '@/components/FaqSection.vue'
import FooterSection from '@/components/FooterSection.vue'
import CookieOptOutBanner from '@/components/CookieOptOutBanner.vue'
import TrustedBySectionV2 from '@/components/TrustedBySectionV2.vue'
import TestimonialsCloud from '@/components/TestimonialsCloud.vue'
import TestimonialsGrid from '@/components/TestimonialsGrid.vue'
import AlertWidget from '@/components/notifications/AlertWidget.vue'
import ConvertKitForm from '@/components/home/ConvertKitForm.vue'
import { getCookie, getParameterByName, setCookie } from '../libs/utils'
import { getNotificaitons } from '../libs/notifications'

export default {
  components: {
    TopBannerV2,
    RestrictedWidth,
    HeroSection,
    CurriculumSection,
    ZeroToHeroSection,
    HighLevelScheduleSection,
    TrustedBySectionV2,
    PricingSection,
    ComparedToOthersSectionV1,
    ComparedToOthersSectionV3,
    HappinessGuarantee,
    AboutSection,
    FaqSection,
    FooterSection,
    CookieOptOutBanner,
    TestimonialsCloud,
    TestimonialsGrid,
    ConvertKitForm,
    AlertWidget
  },
  data () {
    return {
      testimonials1: [
        {
          name: 'Craig Balding',
          tagline: 'Cyber Security Consultant',
          quote: 'Yan delivers high-value training that reflects his deep practitioner experience. I gained more in this two day accelerated learning experience than in some 5-day courses.',
          img: 'website/craig-balding.jpg'
        },
        {
          name: 'Murat Ozcan',
          tagline: 'Staff Engineer @ Extend',
          quote: "Yan's content always delivers, being complete not only from a development perspective, but also complete in testing and CI.",
          img: 'website/murat-ozcan.jpg'
        },
        {
          name: 'Andrew Maughan',
          tagline: 'Senior Backend Developer',
          quote: "Yan's Production-Ready Serverless workshop covered a wide range of different AWS technologies in just 2 days. He really opened my eyes to new ways of developing with AWS serverless technology and how I can apply them in my day job.",
          img: 'website/andrew-maughan.jpg'
        },
        {
          name: 'Billy Gibson',
          tagline: 'Cloud Software Engineer',
          quote: 'Yan is incredibly knowledgeable when it comes to AWS and Serverless. His Production-Ready Serverless workshop was of huge benefit to our teams, even those who have already been working with AWS for the past 12 months.',
          img: 'website/billy-gibson.jpg'
        },
        {
          name: 'Quynh Nguyen',
          tagline: 'Engineering Manager @ SimplyBusiness',
          quote: 'We learnt so much in just 2 days and went from zero to having a good understanding of the Serverless landscape.',
          img: 'website/quynh-nguyen.jpg'
        },
        {
          name: 'Ben Quarless',
          tagline: 'Senior Software Engineer',
          quote: 'This course will definitely increase your AWS skill level. This is a informational filled hands-on course on Serverless computing, which delivers a deep dive into AWS services. Complex concepts are explained in a clear and concise manner.',
          img: 'website/ben-quarless.jpg'
        },
        {
          name: 'Manoj Fernando',
          tagline: 'AWS Community Hero',
          quote: 'The workshop was quite informative and hands-on. In fact, the personal experience that Yan had shared during the workshop was extremely valuable to the professionals who run serverless applications in production.',
          img: 'website/manoj-fernando.jpg'
        },
        {
          name: 'Becky Pauley',
          tagline: 'Cloud Engineer',
          quote: 'Learned so much in two days! The way the course and exercises were structured was really helpful, allowing everyone to learn at their own pace with lot of opportunity for hands-on learning. Yan is a great teacher.',
          img: 'website/becky-pauley.jpg'
        }
      ],
      testimonials2: [
        {
          name: 'Arne Haagensen',
          tagline: 'Head of Public Cloud',
          quote: 'Yan took us through a proper deep dive into the typical AWS services leveraged in serverless solutions built on AWS. Exceptional pairing when questions arrived, and took time to discuss our needs/issues.',
          img: 'website/arne-haagensen.jpg'
        },
        {
          name: 'Martijn van der Kamp',
          tagline: 'DevOps Engineer',
          quote: 'I found the workshop very informative and practical. In other AWS courses, you only learn about the products. But in this workshop, you learn how to use them in the real-world. Yan gave me the building blocks that I can use in my own projects!',
          img: 'website/martin-van-der-kamp.jpg'
        },
        {
          name: 'Kaarel Sööt',
          tagline: 'CTO @ Project Nebula',
          quote: "The workshop was very informative, both in terms of validating some approaches I've taken, and also showing how to improve in other areas. Yan shared his personal experiences with serverless and offered advice on specific challenges.",
          img: 'website/logo-kaarel-soot.png'
        },
        {
          name: 'Farzana Rahman',
          tagline: 'AWS Community Builder',
          quote: 'Best part of this course is you can discuss with Yan about your own production related problems. Thank you so much Yan for your effort.',
          img: 'website/logo-farzana-rahman.png'
        },
        {
          name: 'Mosleh Alnakib',
          tagline: 'Software Engineer',
          quote: 'It has been a truly invaluable learning experience for me. it really is an amazing course and covered very important parts. I would recommend this course to anyone who wants to better understand serverless and AWS.',
          img: 'website/mosleh-alnakib.jpg'
        },
        {
          name: 'Jasper Gabriel',
          tagline: 'Lead Software Engineer',
          quote: "As someone who has learned serverless on his own, going through Yan's workshop gave me a lot of practical lessons and best practices that I can't wait to implement on my own projects. Yan has also been super helpful, and he answers all questions thrown at him.",
          img: 'website/jasper-gabriel.jpg'
        },
        {
          name: 'Subhamay Bhattacharyya',
          tagline: 'AWS Data Engineer',
          quote: 'This is a unique experience packed with lots of advanced Serverless topics. The exercises covers lots of good information on managing AWS costs, improving performance and many more. I recommend this course to anyone who is looking to take their serverless skills to the next level.',
          img: 'website/subhamay-bhattacharyya.jpg'
        },
        {
          name: 'Bruno Ripa',
          tagline: 'Independent Consultant',
          quote: 'I took this workshop to take advantage of the massive experience Yan has in serverless; using such a technology can present challenges that require real world experiences, and Yan is always able to provide the best insights anytime I have any doubt.',
          img: 'website/bruno-ripa.jpg'
        }
      ],
      notifications: []
    }
  },
  async mounted () {
    let affiliateId = getCookie('affiliateId')
    if (!affiliateId) {
      affiliateId = getParameterByName('affiliateid')
      if (affiliateId) {
        // Set affiliateId in cookie for 30 days if it's found in the query string parameter
        setCookie('affiliateId', affiliateId, 30)
      }
    }

    const notifications = await getNotificaitons()
    notifications.forEach(x => this.notifications.push(x))
  },
  methods: {
    clearNotifications () {
      this.notifications = []
    },

    onPppAvailable (amount) {
      this.$refs.pricingSection.handlePppDiscount(amount)
    },

    onBfAvailable (amount) {
      this.$refs.pricingSection.handleBfDiscount(amount)
    }
  }
}
</script>

<style scoped>
  .alert-widget-container {
    position: fixed;
    bottom: -100px;
    left: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .alert-widget {
    animation: slideIn 8s ease-in-out forwards
  }

  @keyframes slideIn {
    0% {
      transform: translateY(0%);
    }
    10% {
      transform: translateY(-250%);
    }
    90% {
      transform: translateY(-250%);
    }
    100% {
      transform: translateY(0%);
    }
  }
</style>
