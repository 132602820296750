import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ThankYouView from '../views/ThankYouView.vue'
import InstructorLedDetailsView from '../views/InstructorLedDetailsView.vue'
import SelfPacedDetailsView from '../views/SelfPacedDetailsView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: ThankYouView
  },
  {
    path: '/instructor-led',
    name: 'InstructorLed',
    component: InstructorLedDetailsView
  },
  {
    path: '/self-paced',
    name: 'SelfPaced',
    component: SelfPacedDetailsView
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'instant'
      }
    }
  }
})

export default router
