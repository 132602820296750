<template>
  <button @click="$emit('clicked')" class="bg-white hover:bg-gray-100 flex flex-col justify-center gap-y-2 items-center px-2 lg:px-4 py-4 rounded-xl lg:rounded-2xl shadow-md shadow-gray-600 hover:shadow-black">
    <!-- small image -->
    <img class="lg:hidden h-16" loading="lazy" :src="`https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,h_60/website/${img}`" />
    <!-- large image -->
    <img class="hidden lg:block h-20" loading="lazy" :src="`https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,h_86/website/${img}`" />
    <p class="text-sm lg:text-md mt-2 font-semibold tracking-tight">{{ name }}</p>
  </button>
</template>

<script>
export default {
  props: ['name', 'img'],
  emits: ['clicked']
}
</script>
