<template>
  <div class="absolute">
    <button @click="handleClose()" class="absolute top-[-5px] right-[-5px] text-gray-500 hover:text-gray-700">
      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
        <path fill-rule="evenodd" d="M10 8.586L15.293 3.293a1 1 0 011.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 011.414-1.414L10 8.586z" clip-rule="evenodd" />
      </svg>
    </button>
    <a @click="handleClick()" href="#pricing" class="flex items-center p-0 rounded-full border-2 border-gray-200 w-80 bg-blue-100 shadow-md shadow-gray-400 hover:shadow-black">
      <div class="mr-3 h-[80px] w-[80px] flex-shrink-0 self-center">
        <img class="rounded-full" :src="notification.imageUrl + `?d=${DEFAULT_AVATAR}&s=80`">
      </div>
      <div class="leading-3">
        <p class="text-lg font-semibold">{{ capitalize(notification.customer) }}</p>
        <p class="text-sm">has joined the next cohort!</p>
        <p class="text-sm">{{ formatTimeAgo(notification.timestamp) }}</p>
      </div>
    </a>
  </div>
</template>

<script>
import { fathomEvent } from '@/libs/analytics'

const DEFAULT_AVATAR = 'https://res.cloudinary.com/prsls/image/upload/f_auto,c_scale,h_80,w_80/website/prsls-default-avatar.png'

export default {
  props: ['notification'],
  emits: ['close-widget'],
  data () {
    return {
      DEFAULT_AVATAR
    }
  },
  methods: {
    capitalize (string) {
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
    },

    formatTimeAgo (timestamp) {
      const now = new Date()
      // timestamp is in the format of "2021-10-01 12:00:00"
      // which is not standard format, and doesn't work in Safari
      const diff = now - new Date(timestamp.replace(' ', 'T'))

      const minute = 60 * 1000
      const hour = 60 * minute
      const day = 24 * hour
      const week = 7 * day

      if (diff < minute) {
        return 'Just now'
      } else if (diff < hour) {
        const minutes = Math.floor(diff / minute)
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`
      } else if (diff < day) {
        const hours = Math.floor(diff / hour)
        return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`
      } else if (diff < week) {
        const days = Math.floor(diff / day)
        return `${days} ${days === 1 ? 'day' : 'days'} ago`
      } else {
        const weeks = Math.floor(diff / week)
        return `${weeks} ${weeks === 1 ? 'week' : 'weeks'} ago`
      }
    },

    handleClick () {
      fathomEvent('clicked-fomo')
    },

    handleClose () {
      fathomEvent('clicked-fomo-close')
      this.$emit('close-widget')
    }
  }
}
</script>
