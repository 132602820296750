<template>
  <div class="flex gap-x-2">
    <span v-if="isPro === true">✅</span>
    <span v-if="isPro === false">❌</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['isPro']
}
</script>
