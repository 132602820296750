<template>
  <li :class="tailwindClasses">
    <img loading="lazy" :src="src" :alt="company">
  </li>
</template>

<script>
export default {
  name: 'LogoComponent',
  props: ['company', 'logo', 'hideOnMobile', 'isLarge'],
  data () {
    const tailwindSize = this.isLarge === true ? 32 : 24
    const pxSize = this.isLarge === true ? 128 : 96

    const url = 'https://res.cloudinary.com/prsls/image/upload/'
    const src = `${url}/f_auto,c_scale,h_${pxSize},w_${pxSize}/${this.logo}`

    let tailwindClasses = this.hideOnMobile === true ? 'hidden sm:flex' : 'flex'
    tailwindClasses += ` place-self-center relative h-${tailwindSize - 8} sm:h-${tailwindSize} w-${tailwindSize - 8} sm:w-${tailwindSize}`

    return {
      tailwindClasses,
      src
    }
  }
}
</script>
