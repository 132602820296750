<template>
  <div class="w-full max-w-5xl mx-auto text-center mt-20">
    <div class="my-8 grid grid-cols-1 sm:grid-cols-2 text-left gap-x-10 text-lg px-7">
      <!-- other workshops -->
      <div class="my-10">
        <h3 class="text-2xl font-semibold mb-10">Other Workshops</h3>
        <div class="flex flex-col gap-y-5">
          <ProOrCon :isPro=false>
            <p>Cost $2,000 or more.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>Taught by full-time trainers who lack real-world experience with the
              technologies they teach.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>Limited access to workshop materials after the workshop.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>No access to the instructor after the workshop and can't ask follow-up questions.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>Only cover how to write and deploy your application. In the real world,
              your job involves much more than that - testing, security, architecture design,
              and perhaps being on-call for your system.</p>
          </ProOrCon>
        </div>
      </div>

      <!-- this workshop -->
      <div class="my-10">
        <h3 class="text-2xl font-semibold mb-10">This Workshop</h3>
        <div class="flex flex-col gap-y-5">
          <ProOrCon :isPro=true>
            <p><strong>More affordable</strong>. And we support <strong>purchase power parity</strong>
              (PPP) for 161 countries.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p>Taught by a respected practitioner with ample <strong>real-world experience</strong>
              of building large serverless architectures.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p><strong>Lifetime access</strong> to the workshop material so you can rewatch and
              <strong>revisit topics when a relevant problem comes up at work</strong>.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p><strong>Become part of my community</strong> and can reach out for help via my
              private Slack channel.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p>True <strong>end-to-end training</strong> designed to prepare you for the
              real-world: from architecture design to writing code, testing, CI/CD, security
              and observability best practices. <strong>Everything you need for building
              production-ready serverless applications</strong>.</p>
          </ProOrCon>
        </div>
      </div>
    </div>

    <div class="my-8 grid grid-cols-1 sm:grid-cols-2 text-left gap-x-10 text-lg px-7">
      <!-- learn from isolated courses -->
      <div class="my-10">
        <h3 class="text-2xl font-semibold mb-10">Learn from isolated courses</h3>
        <div class="flex flex-col gap-y-5">
          <ProOrCon :isPro=false>
            <p>No clear learning path.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>Different teaching styles and methodologies across authors can lead to
              confusion.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>Courses by different authors may vary in quality and depth, resulting in
              uneven knowledge.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>Jumping between courses might leave gaps in understanding, as each course
              doesn't build on the previous one.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>Synthesizing knowledge from various sources is more challenging than
              following a single, integrated course.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>You may receive inconsistent and incompatible advice from different authors.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>You may encounter redundant/duplicated material across different courses.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>Differing support systems across courses can affect the quality and
              consistency of help and feedback.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>Researching and choosing multiple courses requires more time, detracting
              from learning.</p>
          </ProOrCon>
          <ProOrCon :isPro=false>
            <p>You get frustrated and stuck learning by yourself.</p>
          </ProOrCon>
        </div>
      </div>

      <!-- this workshop -->
      <div class="my-10">
        <h3 class="text-2xl font-semibold mb-10">This Workshop</h3>
        <div class="flex flex-col gap-y-5">
          <ProOrCon :isPro=true>
            <p>Ensures all necessary topics are covered systematically, providing a
              <strong>complete understanding of serverless architectures</strong>
              from design to operation.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p>Offers a <strong>unified approach to learning</strong>, with consistent
              teaching styles and methodologies that make it
              <strong>easier to grasp complex concepts</strong>.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p>Concepts are introduced in a logical order, building on previous knowledge,
              which <strong>improves understanding and retention</strong>, and helps you
              see the big picture.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p>Demonstrates how different aspects of serverless applications interconnect,
              offering <strong>practical, real-world examples</strong> that illustrate the
              entire lifecycle.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p>No need to search for and vet multiple courses, letting you focus more on
              actual learning and application.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p>You can interact with and learn from me and your peers, who are all learning
              the same curriculum as you.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p>The workshop material is <strong>continuously updated</strong> to align with
              current industry practices and trends. Ensuring the skills and knowledge you
              gain are <strong>immediately applicable in real-world scenarios</strong>.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p><strong>More cost-effective</strong> than piecing together multiple courses,
              providing comprehensive knowledge for a single investment.</p>
          </ProOrCon>
          <ProOrCon :isPro=true>
            <p>Completing a comprehensive course like this signals to employers a thorough
              understanding and capability in serverless application development, potentially
              helping you in <strong>career advancement</strong>.</p>
          </ProOrCon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProOrCon from '@/components/comparison/ProOrCon.vue'

export default {
  components: {
    ProOrCon
  }
}
</script>
