<template>
  <div id="script-placeholder"></div>
</template>

<script>
export default {
  mounted () {
    const script = document.createElement('script')
    script.async = true
    script.dataset.uid = 'e48eee6818'
    script.src = 'https://theburningmonk.ck.page/e48eee6818/index.js'

    const placeholder = document.getElementById('script-placeholder')
    if (placeholder) {
      placeholder.appendChild(script)
    }
  }
}
</script>
