<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0f0f0f;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.button {
  background-color: #dd4343;
  color: #f9f9f9;
  border-radius: 1rem;
  display: block;
}

.button:hover {
  background-color: #c22626;
}

section {
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}

@font-face {
  font-family: "Corinthia";
  src: local("Corinthia"),
   url(./fonts/Corinthia-Regular.ttf) format("truetype");
}

</style>
